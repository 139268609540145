<template>
  <div v-if="loading" class="loading-indicator">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false
  }),
  methods: {
    hide() {
      this.loading = false;
      document.body.classList.remove("overflow-hidden");
      window.parent.postMessage("atom-loader-hide", window.location.origin);
    },
    show() {
      this.loading = true;
      document.body.classList.add("overflow-hidden");
      window.parent.postMessage("atom-loader-show", window.location.origin);
    },
    isVisible() {
      return this.loading;
    }
  },
  mounted() {
    window.OverlayBehavior.setLoader(this);
  }
};
</script>
